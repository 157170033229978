import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Drawer, Skeleton } from '@mui/material';
import { Box } from '@mui/system';

import {
  OrganizationEntry,
  fetchSearchUsers,
  useGetOrganizationEntry,
} from '@octopus/api';
import { DataGrid, DataGridToolbar, useDataGrid } from '@octopus/ui/data-grid';

import { DataFetching } from '../../modules/dataFetching';
import { prepareDataGridSearchInput } from '../../utils';

import { PendingAccessActionMenu } from './components/pending-access';
import { UsersTableComponents } from './components/table';
import { UserDetails, useUserDetailsControls } from './UserDetails';

export type UsersTableProps = {
  organizationId: string;
};
export function UsersTable({ organizationId }: UsersTableProps) {
  const organizationQuery = useGetOrganizationEntry(
    {
      pathParams: { organizationId },
    },
    {
      enabled: !!organizationId,
    },
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {organizationQuery.isLoading || !organizationQuery.data ? (
        <Skeleton variant="rounded" height={300} width="100%" />
      ) : (
        <UsersDataGrid organization={organizationQuery.data} />
      )}
    </>
  );
}

function UsersDataGrid({ organization }: { organization: OrganizationEntry }) {
  const [userHovered, setUserHovered] = useState<string>(undefined);

  const organizationId = organization.organizationId;
  const searchFilters = UsersTableComponents.getSearchFilters(organization);

  const dataGridProps = useDataGrid({
    key: `users-${organizationId}`,
    filters: searchFilters,
    sorting: { field: 'name', order: 'asc' },
    pagination: {
      rowsPerPageOptions: [50, 100],
      initialRowsPerPageOptionIndex: 1,
    },
  });

  const { sortingProps, searchProps, paginationProps, filteringProps } =
    dataGridProps;

  const searchInput = prepareDataGridSearchInput(dataGridProps);

  const usersQuery = useQuery({
    queryKey: ['searchUsers', organizationId, searchInput, paginationProps],
    queryFn: ({ signal }) => {
      return fetchSearchUsers(
        {
          pathParams: {
            organizationId: organizationId ?? '',
          },
          body: searchInput,
        },
        signal,
      ).then(({ data, ...response }) => ({
        ...response,
        data: data.filter(({ memberships }) => memberships.length > 0),
      }));
    },
    enabled: !!organizationId,
  });

  const {
    selectedUser,
    deselectUser,
    selectUser,
    selectNextUser,
    selectPreviousUser,
    afterUpdate,
  } = useUserDetailsControls(usersQuery, usersQuery.refetch);

  const columns = UsersTableComponents.getColumns(
    organization,
    usersQuery.refetch,
    userHovered,
  );

  return (
    <>
      <Box
        display="flex"
        sx={{
          width: '100%',
          pt: 1,
          mt: 5,
        }}
      >
        <DataGridToolbar
          filters={searchFilters}
          searchProps={searchProps}
          filteringProps={filteringProps}
          sx={{ width: '100%', pb: 0 }}
        >
          <PendingAccessActionMenu organizationId={organizationId} />
        </DataGridToolbar>
      </Box>

      <DataFetching
        fetchResult={usersQuery}
        Loading={() => (
          <Box display="flex" flexDirection="column" gap="8px" mt={1.5}>
            <Skeleton variant="rounded" height={300} width="100%" />
          </Box>
        )}
        Data={({ data }) => {
          const response = data;
          return (
            <Box mt={2} pb={6}>
              {response ? (
                <DataGrid
                  sortingProps={sortingProps}
                  paginationProps={paginationProps}
                  totalRowCount={response.total || 0}
                  getRowId={(row) => row.userId}
                  rows={response.data}
                  columns={columns}
                  onRowClick={({ row }) => selectUser(row)}
                  onRowHover={{
                    enter: (row) => setUserHovered(row.userId),
                    exit: (_row) => setUserHovered(undefined),
                  }}
                />
              ) : null}
            </Box>
          );
        }}
      />

      <Drawer
        anchor="right"
        open={!!selectedUser}
        onClose={deselectUser}
        elevation={2}
      >
        {selectedUser && (
          <UserDetails
            organization={organization}
            user={selectedUser.user}
            controls={{
              close: deselectUser,
              hasNext: selectedUser.hasNext,
              hasPrevious: selectedUser.hasPrevious,
              onNext: selectNextUser,
              onPrevious: selectPreviousUser,
              afterUpdate: afterUpdate,
            }}
          />
        )}
      </Drawer>
    </>
  );
}
